.table-electrics{
    .center {
        text-align: center;
        margin-bottom: 20px;
    }

    .small-container {
        overflow-x: auto;
    }

    .comparison-table {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
        table-layout: fixed;
    }

    .comparison-table th, .comparison-table td {
        padding: 12px 15px;
        border: 1px solid var(--border-color);
        text-align: left;
    }

    @media screen and (max-width: 500px) {
        .comparison-table th {
            padding: 12px 1px;
        }
    }

    .comparison-table th {
        background-color: #f4f4f4;
        font-weight: bold;
    }

    .comparison-table tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    .comparison-table tr:hover {
        background-color: #f1f1f1;
    }

    .advantage-row {
        background-color: #E0FAEC;
    }
    .disadvantage-row {
        background-color: #FFF5F6;
    }

    li {
        list-style: disc outside none;
        display: list-item;
        margin-left: 1em;
    }
}