.scroll-container-car{
    overflow: hidden;
    height: 100%;
    .icon{
        margin-top : 2px;
        margin-left: -5px !important;
    }
}

.left-bar-icon{
    border-left: 2px solid var(--border-color) !important;
    padding-left: 12px;
    margin-left: 8px !important;
}


.right-sort{
    margin-right: 10%;
    //margin-right: 5px;
    .activeButton{
        background-color:var(--background-color)!important;
        h5{
            color :var(--text-color) !important;
        }
        .icon-multiSelect{
            color :var(--background-color) !important;
        }
    
    }
}