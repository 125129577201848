.accordion-style{
    border-radius:  1% !important;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: 0px 0px 0px 0px !important;
}

.accordion-style::before{
    background-color: transparent !important;

}