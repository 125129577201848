


.input-area{
        cursor: pointer;
        background-color: var(--box-background);
        color: var(--text-color);
        width: 100%;
        border: 2px dashed var(--border-color);
        border-radius: var(--border-radius);
        margin: auto;
        height: 200px;
        cursor: pointer;
    

    p{
        color: var(--grey-color);
        text-align: center;
        margin: auto;
        
    }

    svg{
        color: var(--grey-color);
        font-size: 50px;
        margin: auto;
    }

    .middle{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}