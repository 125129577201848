@import '../../../index.scss';


.right-bar{
    overflow: hidden;
    height: auto;
    padding: 10px;
    background-color : var(--background-color)-secondary;
    @media screen and (max-width: 450px) {
        grid-template-rows: minmax(auto, 60px) minmax(auto, 60px);
    }
}


.main-page{
    display: grid;
    /* Make the left bar to fit content and right side to take the rest of the space */
    grid-template-columns: minmax(auto, 200px) auto;
    grid-template-rows: minmax(auto,100%);

    @media screen and (max-width: 1200px) {
        grid-template-columns: 60px auto;
        
    }
}