@import '../../../../index.scss';

.select-card-container {
    width: 100%;
    border: 2px solid var(--secondary-color);
	border-radius: var(--border-radius);
}

.selected-card{
    background-color: var(--box-background);
    border-color: var(--primary-color);
}


