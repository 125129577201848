@font-face {
  font-family: 'Inter';
  src: url("./assets/fonts/Inter/Medium.ttf");
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 44.8px */
}

@font-face {
  font-family: 'Inter Bold';
  src: url("./assets/fonts/Inter/Medium.ttf");
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 44.8px */
}

@font-face {
  font-family: 'Inter Bold';
  src: url("./assets/fonts/Inter/Medium.ttf");
  font-style: normal;
  font-weight: 900;
  line-height: 140%; /* 44.8px */
}

/* Fonts */
$main-font : "Inter";
$title-font : "Inter Bold";
$strong-font : "Inter Strong";

/* Main Colors */
$primary-color : #11B0C6;
$secondary-color :#c5f7ffde;
$red-color : rgb(193, 23, 23);
$green-color : #b3e8b6;
$orange-color : #ffa735;
$border-radius : 10px;
$header-height : 60px;
$header-height-mobile : 70px;
$tag-padding: 2px 8px;
$form-element-height: 40px;
//$mobile-width : 500px;

$h1 : 32px;
$h2 : 24px;
$h3 : 18px;
$h4 : 16px;
$h5 : 14px;
$h6 : 12px;

$themes: (
  dark: (
    mobile-width : 500px,
    color-mode : "dark",

    main-font : $main-font,
    title-font : $title-font,
    strong-font : $strong-font,

    primary-color : $primary-color,
    secondary-color : $secondary-color,

    red-color : $red-color,
    green-color : $green-color,
    orange-color : $orange-color,
    border-radius : $border-radius,
    header-height : $header-height,
    header-height-mobile : $header-height-mobile,

    tag-padding: $tag-padding,
    form-element-height: $form-element-height,

    background-color : #191919,

    button-color : $primary-color,
    button-white-color : #ffffff,
    button-white-background: #0B0D13,
    button-white-text-color: #2e2e2e,
    button-white-border-color: #92959c,
    shadow-color : #030303,

    input-background : #3a3a3a,

    text-color : #efefef,
    text-color-secondary : #9d9d9d,

    grey-color : #535865,
    color-placeholder : #c1c1c1,

    border-color : #5e5e5e,
    box-background : #242424,

    dropdown-background : #191919
  ),
  light: (
    mobile-width : 500px,
    color-mode : "light",
 
    main-font : $main-font,
    title-font : $title-font,
    strong-font : $strong-font,

    primary-color : $primary-color,
    secondary-color : $secondary-color,

    red-color : $red-color,
    green-color : $green-color,
    orange-color : $orange-color,
    border-radius : $border-radius,
    header-height : $header-height,
    header-height-mobile : $header-height-mobile,
    tag-padding: $tag-padding,
    background-color : #ffffff,
    
    button-color : $primary-color,
    shadow-color : #e6e6e6,
    button-white-color : #ffffff,
    button-white-background: #0B0D13,
    button-white-text-color: #2e2e2e,
    button-white-border-color: #92959c,

    form-element-height: $form-element-height,

    input-background : #dcdcdc,
    
    text-color : #2e2e2e,
    text-color-secondary : #444444,

    grey-color : #a5a5a5,
    color-placeholder : #959595,

    border-color : #e6e6e6,
    box-background : #F6F8FA,

    dropdown-background : #ffffff
  )
);

// Define default theme (light) variables globally
@each $color, $value in map-get($themes, 'light') {
  :root {
    --#{$color}: #{$value};
  }
}

// Define dark theme variables under a specific class
.theme-dark {
  @each $color, $value in map-get($themes, 'dark') {
    --#{$color}: #{$value};
  }
}
