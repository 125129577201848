@import '../../../../index.scss';

.selectInputs {
    width: auto;
}

.container-multiSelect {}

.itemlistselect {
    padding: 5px 10px;
    cursor: pointer;
    text-align: left;

    &:hover {
        background-color: var(--secondary-color);
        color: var(--text-color);
        border-radius: var(--border-radius);
    }
}