@import '../../../index.scss';


a{
    text-decoration: none;
    cursor: pointer;
    &:hover{
        transition: 0.3s;
        opacity: 0.6;
    }

}

.button, .button-white{
    margin: 5px 0; /* Remove auto from horizontal margin */
    width: 100%;
    opacity: 300ms ease-in-out 0s;
    border-radius: $border-radius;
    border: 0px;
    cursor: pointer;
    transition: box-shadow var(--duration-short) ease;
    -webkit-appearance: none;
    appearance: none;   
    text-align: center;
    padding: 6px 10px;

    &.center{
        margin: auto !important;
    }

    &:hover{
        box-shadow: 0px 0px 20px var(--shadow-color);
        /* Animation */
        transition: 0.3s;   
        
    }

    &.fit{
        width: fit-content;
    }

}

.button{
    background-color: var(--button-color) !important;
    height: var(--form-element-height);    
    h1,h2,h3,h4,h5,h6,span,body,p,a{
        color: var(--background-color);
        text-align: center;
    }

    &.primary{
        background-color: var(--background-color);
        h1,h2,h3,h4,h5,h6,p,span,body,a{
            color: var(--text-color) !important;
        }
    }
}

.button-white{
    background-color: var(--button-white-color) !important;
    h1,h2,h3,h4,h5,h6,span,body,p,a{
        color: var(--button-white-text-color);
        text-align: center;
    }

    &.primary{
        background-color: var(--button-white-color);
        h1,h2,h3,h4,h5,h6,p,span,body,a{
            color: var(--button-white-text-color) !important;
        }
    }
    border: 1px solid #E2E4E9;

    .icon{
        color: var(--text-color);
    }
}


.link{
	color: var(--primary-color) !important;
}