@tailwind base;
@tailwind components;
@tailwind utilities;
@import './colors.scss';

::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	color: var(--color-placholder);
}

:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: var(--color-placholder);
	opacity: 1;
}

::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: var(--color-placholder);
	opacity: 1;
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: var(--color-placholder);
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: var(--color-placholder);
}

::placeholder {
	/* Most modern browsers support this now. */
	color: var(--color-placholder);
}

#root,
html {
	background-color: var(--background-color);
	/* Fill the entire screen */
	height: 100%;
	width: 100%;

}

h1,
h2,
h3 {
	font-family: var(--title-font);
}

h4,
h5,
h6,
span,
body,
input {
	font-family: var(--main-font);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--text-color);
}

body {
	color: var(--text-color-secondary);
}

.hero {
	width: 90%;
	margin: auto;
	height: 1200px;
}

h1 {
	font-size: $h1;
}

h2 {
	font-size: $h2;
}

h3 {
	font-size: $h3;
}

h4 {
	font-size: $h4;
}

h5 {
	font-size: $h5;
}

h6 {
	font-size: $h6;
}

span {
	font-size: 0.75rem;
}

p {
	font-size: $h5;
}

body {
	font-size: 1rem;
}

.grey {
	color: var(--grey-color);
}

.bold-text {
	font-family: var(--title-font);
}

.strong-text {
	font-family: var(--title-font);
	font-weight: 620;
}

.grey-2 {
	color: var(--grey-color);
	opacity: 0.6;
}

.hover-item {
	&:hover {
		opacity: 0.7;
		/* Animation */
		transition: 0.3s;
	}
}

.divider{
	width: 100%;
	height: 1px;
}

.divider-vertical  {
	width: 1px;
	height: 100%;
}

.divider,
.divider-vertical {
	opacity: 0.3;
	position: relative;
	display: block;
}

.divider{
	background-color: var(--grey-color);
	border-bottom: 1px solid var(--grey-color);
}

.divider-vertical{
	background-color: black;
	border-bottom: 1px solid black;
}


.margin {
	margin-top: 30px;
	margin-bottom: 10px;
}

.link {
	color: var(--primary-color);
}

.box-background {
	background-color: var(--box-background) !important;
}

.secondary-background {
	background-color: var(--secondary-color);
}

.secondary-text {
	color: var(--secondary-color);
}

.primary-text {
	color: var(--primary-color) !important;
}

p {
	font-size: 14px;
}

.container {
	padding: 24px;
	width: auto;
	border-radius: var(--border-radius);
	margin: auto;
	display: grid;
	height: fit-content;
	max-width: 1024px;
	margin-top: 12px;
	margin-bottom: 12px;
	width: 100%;
	grid-template-columns: 100%;

	&.primary {
		background-color: var(--primary-color);
		color: var(--background-color);

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		span,
		body {
			color: var(--background-color);
		}
	}
}

.fifty {
	display: grid;
	grid-template-columns: 50% 50%;
}

.total-page {
	position: absolute;
	width: 100%;
	height: 100vh;
	background-color: var(--background-color);
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.container-center {
	position: relative;
	/* Sets the position context to the container itself */
	display: flex;
	align-items: center;
	/* Center vertically */
	justify-content: center;
	/* Center horizontally */
	height: 100%;
	/* Full height of the parent */

	.inside {}
}

.padding {
	padding: 12px 16px;
}

.box-container {
	background-color: var(--background-color);
	padding: 12px 24px;
	border: 1px solid var(--border-color);
	border-radius: $border-radius;
	width: fit-content;
	min-width: fit-content;
	position: relative;
	display: inline-block;
	height: fit-content;
	width: 100%;
	grid-template-columns: 100%;
	word-break: break-word;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	span,
	body {}

	.title {
		margin-bottom: 8px;
	}

	svg {
		color: var(--primary-color);
		margin-bottom: 10px;
	}
}

.placeholder {
	color: var(--color-placeholder) !important;
}

.space {
	margin-top: 10px;
}


.flex {
	display: flex;
	flex-wrap: wrap;
	/* Allows items to wrap onto the next line */
	justify-content: space-between;
	/* Distributes space between items */
	align-items: center;
	/* Aligns items vertically */
	gap: 10px;
	/* This sets both row and column gaps */
}

;

.section {
	padding-top: 24px;
	padding-bottom: 24px;

	.sub {
		padding: 0 2%;
	}
}

.min-width {
	width: fit-content;
}


svg {
	transform: scale(0.7);
}


.active {

	svg,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	span,
	body {
		color: var(--text-color) !important;
	}
}

.inline {
	display: inline-flex;
	margin: 5px;

}

svg {
	width: 10px;
}

.no-margin {
	margin: 0px;
}

.inline-no-margin{
	display: inline-flex;
	margin: 0px;
}
.small-container {
	margin: 16px 0px;
}

.grid-33 {
	display: grid;
	grid-template-columns: 33% 33% 33%;
	/* Set space across the columns */
	gap: 16px;
	grid-auto-rows: 1fr; /* Allow rows to grow equally */
}

.grid-30-fit {
	display: grid;
	grid-template-columns: repeat(auto-fit, 30%);
	/* Set space across the columns */
	gap: 5%;
}

.grid-50 {
	display: grid;
	grid-template-columns: 50% 50%;
	/* Set space across the columns */
	gap: 5%;
	width: 95%;
	/* Remove the gap at the end */
}

.grid-60-40 {
	display: grid;
	grid-template-columns: 60% 40%;
	/* Set space across the columns */
	gap: 5%;
	width: 95%;
	/* Remove the gap at the end */
}

.grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: 16px;
	/* Adjust the gap as needed */

	.left {
		justify-self: start;
	}

	.right {
		justify-self: end;
	}
}

.green {
	color: var(--green-color);
}

.divider {
	width: 100%;
	opacity: 0.3;
	position: relative;
	border-bottom: 1px solid var(--grey-color);
	margin: 10px 0px;
}

.no-padding {
	padding: 0px;
}

.left-text {
	text-align: left;
}

.right-text {
	text-align: right;
}

.center-text {
	text-align: center;
	margin: auto;
}

.box {
	display: block;
	position: relative;
}

.number-blue {
	background-color: var(--secondary-color);
	border-radius: 35px;
	height: fit-content;
	padding: 4px 10px;
	display: block;
	position: relative;
	text-align: center;

}

.stack-top {
	display: grid;
	justify-items: center;
}

.stack {
	display: grid;
	place-items: center;
	justify-content: center;
	justify-items: center;
}

.stack>*,
.stack-top>* {
	grid-row: 1;
	grid-column: 1;
}

.btn-disabled {
	opacity: 0.4 !important;
	cursor: default !important;
}

.tag{
    background-color: var(--secondary-color);
    border-radius: var(--border-radius);
    padding: var(--tag-padding);
    width: fit-content;
	line-height: 20px;


    &.green{
        background-color: var(--green-color);
        color: #30634A!important;
    }

    &.red{
        background-color: var(--red-color);
        //color: #961a1a!important;
		color: white !important;
    }

	&.orange{
        background-color: var(--orange-color);
        //color: #961a1a!important;
		color: white !important;
    }
}

.available{
	
	.inline{
		 margin: 0px !important;
		 padding: 0px !important;
	 }
	
	svg{
		 padding: 0px !important;
		 margin: 0px !important;
		 height: 15px;
		 width: 15px;
	 }
 
 }

 .inputTextDiv{
    width: 100%;

    input,textarea{
        width: 100%;
        border-radius: 5px;
        padding :5px 10px;
        font-size: 12px;
        background-color: var(--background-color);
        border : 1px solid var(--border-color);
        margin-bottom : 5px;
        color : var(--text-color);    
		height: var(--form-element-height);    
    }
    
    h5{
        padding: 5px;
        color : var(--text-color-secondary);
    }
}

.input-error{
    display: block;
    position: relative;
    color : $red-color !important;
}

div.input-error-box input {
	border : 1px solid $red-color !important
}

.error-box{
    border : 1px solid $red-color !important
}

.error-text{
    color :$red-color !important
}

.icon{
    width: 20px !important;
    padding: 3px !important;

    svg{
        margin-bottom : 0px !important;
    }
}

.selected-button{
	height: var(--form-element-height);    
    display: grid;
    grid-template-columns: auto 25px;
	align-items: center;
	background-color: var(--dropdown-background);
	width: auto;
    cursor: pointer;
    border : 1px solid var(--border-color);
    border-radius: var(--border-radius);
	padding: 0px 5px;
    h1,h2,h3,h4,h5,h6,label,span{
        color :var(--text-color) ;
    }

    svg{
        float: right;
        margin : 0px;
        margin-top : 2px !important;
    }
}

input,
input::placeholder {
    font-size: $h5;
}
textarea,
textarea::placeholder {
    font-size: $h5;
}