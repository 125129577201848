.find-your-car-container{
    padding: 5%;
    min-height: 400px;
    //overflow: hidden;
    display: block;
    z-index: 0;
    position:relative;
    background-color: white;

    .content{
        background-color: transparent;
        border-radius: var(--border-radius);
        padding: 20px;
        width: 60%;
        max-width: 80%;
        z-index: 3;
        position: relative;
        margin: 0 10px;
    }
}