.green-container{
    display: flex;
    position: relative;
    background-color: var(--green-color);
    padding: 12px;

    h5{
        color: #176448;
    }

    svg{
        color: #176448;
    }
}


.bottom-page-container{
    /* Vertically align bottom */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 65%;
    
    .grid{
        height: 70px;
        overflow: hidden;
        padding: 0 !important;
        border : 1px solid var(--border-color);
        border-radius: var(--border-radius);
        background-color: var(--background-color);
        /* Divide grid in 3 columns */
        display: grid;
        grid-template-columns: 25% 75%;
    }
    
    .tag{
        background-color: var(--background-color);
        border : 1px solid var(--border-color);
        border-radius: var(--border-radius);
        align-items: center;
        text-align: center;
        margin: 0px auto;
    }
    .bold-text{
        margin-bottom : 10px;
    }
    img{
        margin: 0 !important;
        height: 100%;
        /* Make image responsive */
        width: 100%;
        object-fit: cover;
        border-radius: 0;
        display: block;
        position: relative;
    }
}