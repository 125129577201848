.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 0px;
}

.card {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  width: 280px;
  margin: 10px;
  overflow: hidden;
  display: inline;
  flex-direction: column;

  .card-image {
    width: 100%;
    height: auto;
  }

  .card-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    .card-title {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .card-rating {
      margin-bottom: 10px;
    }

    .card-text {
      margin-bottom: 20px;
    }
  }

  .card-footer {
    padding: 10px 20px;
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .car-model {
      font-weight: bold;
    }

    .show-similar {
      color: #007bff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
