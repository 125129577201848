@import '../../../index.scss';

.modal-box{
    background-color: var(--background-color);
    position: absolute;
    box-shadow: 10px 1px 100px 1px var(--shadow-color);
    border-radius: 5px;
    overflow: hidden;
    height: min-content;
    z-index: 10;
    padding : 20px;
    width: max-content;
}

.modal-middle{
    background-color: var(--background-color);
    /* Position middle of the screen */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);   
    box-shadow: 10px 1px 100px 1px var(--shadow-color); 
    border-radius: 5px;
    overflow: hidden;
    z-index: 1000;    
    height: min-content;    

    .closeIcon{
        position: absolute;
        top:0px;
        height: 0px;
        margin-left: 85%;
    }

    @media screen and (min-width: 500px) {
        height: min-content;    

    }

    /* Mobile */
    @media screen and (max-width: 500px) {
        width: 80%;
        overflow-y: scroll;
        overflow-x: hidden;
        margin: auto;
        max-height: 100%; 
    }
}

.background-modal{
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    opacity: 0.8;
    width: 100vw;
    z-index: 50;
    background: black
}

.hideModal{
    display: none;
}