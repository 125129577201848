@media screen and (min-width: 500px) {
    .cant-find-image{
        top:0;
        height: 200px;
        width: 100%;
        object-fit: cover;
    }
}

@media screen and (max-width: 500px) {
    .cant-find-image{
        height: 200px;
        width: 100%;
        object-fit: cover;
    }
}

.cant-find-container{
    display: grid;
    border-radius: var(--border-radius);
    height: 200px;
    overflow: hidden;
}

.cant-find-container>*,
.cant-find-container>* {
	grid-row: 1;
	grid-column: 1;
}

.cant-find-background{
    z-index: 50;
    background:  #00B1C6;
    width: 100%;
    height: 200px;
    opacity: 0.65;
    z-index: 2;
}