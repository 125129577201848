

.image-color{
    width: 25px;
    height: 25px;
    display: inline-block;
    border-radius: 50px;
    border : 1px solid #ccc;
    margin-right : 4px;
    cursor: pointer;    
    &:hover{
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
    }
    
    &.isActive{
        border : 2px solid var(--primary-color)
    }
}

.filters > * {
    margin-bottom: 5px;
}

@media screen and (max-width: 500px) {
    
    .filters{
        overflow-y: scroll;
        max-height: 80%;
    }

    .filter-container-closed{
        width: 100px;
    }

    .filter-container-open,.filter-container-close{
        overflow-y: hidden;
        position: fixed;
        height: 100%;
        width: 75% !important;
        margin-left: -75%;
        z-index: 50;
        background-color: var(--background-color);
        top:0;
        padding: 12px 24px;
    }

    .filter-container-open{
        animation: filters_open 100ms linear;
        animation-fill-mode: forwards;
    }

    @keyframes filters_open {
        0% {
            margin-left: -75%;
            opacity: 0;
        }
        100% {
            margin-left: 0%;
            opacity: 1;
        }
    }

    .filter-container-close{
        animation-fill-mode: forwards;
        animation: filters_close 100ms linear;
    }

    @keyframes filters_close {
        0% {
            margin-left: 0%;
            opacity: 0;
        }
        100% {
            margin-left: -75%;
            opacity: 1;
        }
    }

    .filter-button{
        background-color: var(--button-color);
    }

    .filter-white-title{
        color: var(--background-color);
    }
}