@import '../../../../index.scss';

.modal-back{
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    height: 100%;
    width: 100vw;
}

.showModal{
    display: block !important;
}

.hideModal{
    display: none !important;
}

.h-32{
    height: 32px !important;    
}

.div-selectBody{
    background-color: var(--background-color);
    position: absolute;
    margin-top: 10px;
    padding: 10px;
    width: auto;
    height: min-content;
    border-radius: var(--border-radius);
    border : 1px solid var(--border-color);
    z-index: 1000;
    //if this is changed openDropDownStatus must also be changed
    min-width: 150px;
}

.icon-multiSelect{
    width: auto;
}

.selectorMultiSelect{
    margin: 0;
    z-index: 2;
    margin-top: 5px;
    height: 25px;
    h5,h6 {
        margin : 0;
        margin-left: 5px;
    }
    .align{
        vertical-align: middle;
    }

}

.clear-box-container{
    border-top: 1px solid var(--background-color-grey);
    margin-top : 10px;
}

.children-container{
    overflow-y: scroll;
    height: auto;
    max-height: 240px;
}

.activeButton{
    background-color:var(--primary-color)!important;
    h5{
        color :var(--background-color) !important;
    }
    input{
        background-color:var(--primary-color)!important;

    }
    .icon-multiSelect{
        color :var(--background-color) !important;
    }
}

.item-input {
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    outline: none;
    margin: 0px !important;
}