@import '../../../index.scss';

.left-bar{
    background-color: var(--background-color);
    width: inherit;
    padding : 20px 5px;
    height: 100vh;
    border-radius: 0px 0px var(--border-radius) 0px;
    border-right : 1px solid var(--secondary-color);
    
    @media screen and (max-width: 450px) {
        width: auto;
        padding-top: 0;
        height: min-content;
    }

    
    h5{
        margin-left: 4px;
        margin-top : 3px;
        color: var(--text-color-secondary);
    }
    
    svg{
        color: var(--text-color-secondary);
    }
    
    .nav-link{
        width: 100%;
        min-width: max-content;
        margin: auto;
        position: relative;
        display: flex;
    }

    h6{
        margin-left: 10px; 
        margin-bottom: 10px;
        color: var(--text-color-secondary);

        @media screen and (max-width: 1200px) {
            display: none;
        }
    }
    
    .bottom-div{
        /* Bottom of the vertical bar using end */
        position: relative;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

    }
}


.isActive{
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    h5{
        color : var(--primary-color) !important;
    }

   svg{
        transform: scale(0.7);
        color : var(--primary-color) !important;
    }

    @media screen and (max-width: 450px) {
        border-top : 2px solid $primary-color;
        border-right : 2px solid $primary-color;
        padding-top: 0;
        border-radius: 5px 5px 0px 0px;
        height: min-content;
    }
}

.title-leftBar{
    margin : 0px 0px;
    width: 100%;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    height: min-content;
    cursor: pointer;
    color : var(--text-color);
    h4{
        color : var(--text-color);
    }
}

.container-box-left-bar{
    display: grid;
    grid-template-rows: auto;
    padding: 10px 0px;

    @media screen and (max-width: 450px) {
        grid-template-columns: auto;
        grid-template-rows: none;
        padding : 0;
        padding-top : 15px;
    }

}

.logo-container{
    //border-bottom : 1px solid var(--border-color);
    padding: 4.5px;
    img{
        margin : auto;
        display: block;
        position: relative;
        width: 50px;
    }

    /* Mobile  */
    @media screen and (max-width: 450px) {
        display: grid;
        padding: 10px 5px;
        grid-template-columns: auto auto auto;
        justify-content: space-between;
        img{
                display: block;
                margin-left: 10px;
                position: relative;
                width: 40px;
            }

    }

 /* Mobile  */
    @media screen and (max-width: 350px) {
        img{
                display: none;
          
            }

    }
}


/* Small PC */
@media screen and (max-width: 1200px) {
    .left-bar-text{
        display: none;
    }
    .left-bar-icon{
        display: block !important;
        margin : auto;
        position: relative;
        align-items: center;
    }
    .icon-leftBar{
        display: block !important;
        position: relative;
        margin: auto;
    }
}


/* Tablet */
@media screen and (max-width: 768px) {
    .leftbar-text{
        display: none;
    }
    .left-bar-icon{
        display: block !important;
        margin : auto;
        position: relative;
        align-items: center;
    }
    .icon-leftBar{
        display: block !important;
        position: relative;
        margin: auto;
    }
}


/* Mobile */
@media screen and (max-width: 450px) {
   
   
}
