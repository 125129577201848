@import '../../../../index.scss';

.signup-grid, .thank-you-grid{
    display: grid;
    grid-template-columns: 100%;
    position: relative;
    height: 100vh;
}

.outside-container{
  /* Align vertically & horizontally */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: var(--background);
}

.inside-container{
  /* Align vertically & horizontally */
  display: flex;
  border : 1px solid var(--border-color);
  border-radius: $border-radius;
  max-width: 400px;
  width: 90%;
  padding : 20px 0px;
  margin: auto;
  justify-content: center;
  align-items: center;
  height: fit-content;
  background-color: var(--box-background);
}

.signup-container{
    display: grid;
    grid-template-rows: auto;
}

.goToLoginContainer{
  cursor: pointer;
  margin-top : 20px;
}

.text-container-signup{
    margin : auto;
    width: auto;

}

.loading-gif{
  width: 25px !important;
}

.text-container-sign{
  width: 90%;
  margin: auto;
    img{
        width: 120px;
        margin : auto;
    }

}

@-webkit-keyframes gradient{
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
     background-position: 0% 50%;
  }
}
@keyframes gradient{
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
     background-position: 0% 50%;
  }
}

.google-division{
  border-bottom : 1px solid var(--border-color);
}