@import "../../../index.scss";

.eletric-hero{
    width: 100%;
    z-index: 0;
    padding-top : 50px;
    min-height: 400px;
    overflow: hidden;
    display: block;
    position:relative;

    .background{
        background:linear-gradient(to right, var(--primary-color) 40%, transparent 100%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    .content{
        border-radius: var(--border-radius);
        max-width: 100%;
        z-index: 3;
        position: relative;
        margin: 0 10px;

        h1,h2,h3,h4,h5,h6,p{
            color: var(--background-color);
            max-width: 500px;
        }

        button{
            background-color: var(--background-color);

            h5{
                color: var(--text-color);
            
            }
        }
    }

    .icon{
        padding: 10px;
        background-color: var(--secondary-color);
        border-radius: var(--border-radius);

        width: 100% !important;
        height: 100% !important;
        svg{
            width: 50px;
        }
    
    }
}

.hero-image{
    position: absolute;
    right: 0;
    height:  100%;
    z-index: 1;
    /* Set opacity to 0.6 from left to right 1 */
    opacity: 1;
    /* Set the image to the right */   
    top: 0;
}