.new-user-promotion-modal-stack-container{
    height: 200px;
}
.new-user-promotion-container {
    position: relative;
    width: 200px;
    height: 200px;
}
  
.new-user-promotion-container::after {
content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, white 20%, transparent 100%);
}

.new-user-promotion-image{
    width: 100%; 
    height: 100%;
}