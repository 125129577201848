

@media screen and (min-width: 500px) {
    .grid-filter-car{
        display: grid;
        grid-template-columns: minmax(150px, 20%) auto;
        grid-template-rows: 100%;
        /* Add space between the cards */
        gap: 20px;
    }
}

@media screen and (max-width: 500px) {
    .grid-filter-car{
        display: inline;
        padding: 20px !important;
    }

    .section{
        padding-left: 5px;
	    padding-right: 5px;
    }
}