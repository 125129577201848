@media screen and (min-width: 500px) {
    .header {
        background-color: var(--background-color);
        border-bottom: 1px solid var(--secondary-color); /* Border at the bottom */
        padding: 0 20px; /* Optional: Add padding to left and right */
        margin: auto;
        height: var(--header-height);
        min-height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.header-margin{
	padding-bottom: var(--header-height);
}

@media screen and (max-width: 500px) {
    .header-wrapper{

        border-bottom: 1px solid var(--secondary-color); /* Border at the bottom */
    }
    .header {
        margin-top: 5px;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: var(--header-height-mobile);
        min-height: var(--header-height-mobile);
        min-width: 60px;
        width: 100%;

        padding-top: 24px;
        padding-bottom: 24px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .header-open{
        height: 200px;
    }

    .header-margin{
        padding-bottom: var(--header-height-mobile);
    }
}

.logo {
}

.border-left{
    background-color: var(--border-color);
    height: 30px;
    width: 1px;
}

.icon-button{
    height: 35px;
    padding: 0px 12px !important;
    line-height: normal;

    .inline{
        margin :0px !important;
    }
    
    svg{
        margin :0px !important;
        
        color : var(--background-color);
        width: 25px !important;
        height: fit-content;
        display: inline-block;
    }
}

.language{
    width: 30px;
    height: 30px;
    border-radius: 30px;
}

@media screen and (max-width: 500px) {
    .account-header-container{
        justify-content: space-between;
        align-items: center;
        height: var(--header-height-mobile);
    }
}

.account-header-container{
    display: flex;
    background-color: var(--background-color);

    
    svg{
        width: 30px;
        height: 30px;
    }

    .favorite-count{
        background-color: var(--primary-color);
        color: var(--background-color);
        border-radius: 4px;
        padding: 0 5px;
        margin-left: 5px;
        position: absolute;
        margin-top: -6px;
        margin-left: 16px;
    }

    .text{
        /* Align Vertically */
        display: flex;
        align-items: center;
        margin-left: 2px;
        margin-top : 6px;
    }
}

@media screen and (min-width: 500px) {
    .nav-links {
        height: 60px;
        display: flex;
        align-items: center;
        margin-left: 20px; /* Adjust margin as needed */
        flex-grow: 1; /* Allows nav-links to take available space */
    }
}

@media screen and (max-width: 500px) {
    .nav-links{
        left: 0;
        margin-top: -5px;
        display: block;
        position: absolute;
        align-items: center;
        width: 0%;
        height: 100vh;
        background-color: var(--background-color);
        animation: nav_links_open 100ms linear;
        animation-fill-mode: forwards;
    }

    .nav-links-closing {
        left: 0;
        margin-top: -5px;
        margin-left: -75%;
        display: block;
        position: absolute;
        align-items: center;
        opacity: 0;
        width: 0%;
        height: 0vh;
        background-color: var(--background-color);
        animation-fill-mode: forwards;
        animation: nav_links_close 100ms linear;
    }

    @keyframes nav_links_open {
        0% {
            width: 0%;
            opacity: 0;
        }
        100% {
            width: 75%;
            opacity: 1;
        }
    }

    @keyframes nav_links_close {
        0% {
            height: 100vh;
            opacity: 1;
            width: 75%;
            margin-left: 0;
        }
        99% {
            height: 100vh;
            margin-left: -75%;
        }
        100%{
            height: 0vh;
            opacity: 0;
        }
    }
}

@media screen and (min-width: 500px) {
    .nav-links h5 {
        margin: 0 15px;
        height: 100%;
        display: flex;
        height: 60px;
        align-items: center;
    }
}

@media screen and (max-width: 500px) {
    .nav-links h5 {
        margin: 0 15px;
        display: flex;
        height: 30px;
        align-items: center;
        font-size: 11px;
    }
}

.username-account-icon {
    text-align: center;
    padding: 8px;
    border: none;
    border-radius: 35px;
    min-width: 35px;
    min-height: 35px;
    width: fit-content;
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}


@media screen and (min-width: 500px) {
    .isActiveHeader {
        border-bottom: 2px solid var(--primary-color);

        h5 {
            color: var(--primary-color) !important;
        }
    }
}

@media screen and (max-width: 500px) {
    .isActiveHeader {
        h4 {
            color: var(--primary-color) !important;
        }
    }
}