.main-hero{
    padding: 5%;
    min-height: 400px;
    //overflow: hidden;
    display: block;
    z-index: 0;
    position:relative;

    .hero-image-mobile, .hero-image{
        position: absolute;
        right: 0;
        z-index: 1;
        top: 0;
    }
    .hero-image{
        height: auto;
        width:  100%;
    }

    .hero-image-mobile{
        width: auto;
        height: 100%;
    }

    .background{
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
    .content{
        background-color: var(--background-color);
        border-radius: var(--border-radius);
        padding-right: 32px;
        padding-left: 32px;
        padding-top: 12px;
        max-width: 695px;
        z-index: 3;
        position: relative;

        @media screen and (min-width: 500px) {
            margin-left: 10%;

            .children-container{
                max-height: 190px;
            }
        }

        @media screen and (max-width: 500px) {
            .children-container{
                max-height: 150px;
            }
        }
    }
}


@media screen and (max-width: 500px) {
    .main-hero {
        .content{
            max-width: 100%;
        }
    }
}

.small-container{
    overflow-x: visible !important;
}