@import '../../../index.scss';


@media screen and (min-width: 500px) {
  .loading-container{
    width: 100vw;
  }
}

@media screen and (max-width: 500px) {
  .loading-container{
    width: 100vw;
  }
}

@keyframes breathe {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.contained-loading-container{
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
      width: 100px;
      animation: breathe 1000ms infinite,
  }
}