@import '../../../index.scss';

.loading-bar-container{
  background-color: var(--background-color);
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;  
  flex-direction: column;
}

.loading-bar-background,.loading-bar-current-background{
  height: 10px;
  border-radius: 5px;
  background-color: var(--button-color) !important;
}

.loading-bar-background{
  width: 100%;
  opacity: 0.5;
}