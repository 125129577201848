
.dual-option-container{
    margin : 8px 0px;
    width: 100%;
    padding: 6px;
    text-align: center;
    /* Align text vertically */
    justify-content: center;
    align-items: center;
    position: relative;
    height: var(--form-element-height);
    background-color: var(--box-background);
    border-radius: var(--border-radius);

    &:hover{
        cursor: pointer;
    }
    
    h5{
       padding: 4px 0px;
       cursor: pointer;
    }

    .not-active{
        opacity: 0.8 !important;
    }
    
    .active{
        background-color: var(--button-color);
        color: var(--background-color);
        border-radius: var(--border-radius);
        /* Bold text */
        font-weight: bold;
        
    }
    
}
