@import '../../../index.scss';

.request-form-container {
    .width98{
        width: 98% !important;
    }

    .width100-with-gap-space{
        width: 103% !important;
    }
}

// @media screen and (min-width: 500px) {
//     .request-modal-container {
//         position: relative;
//         width: 100%;
//         max-height: 90vh;
//         overflow-y: auto;
//         height: 100%;
//     }
// }

// @media screen and (max-width: 500px) {
//     .request-modal-container {
//         position: relative;
//         width: 300px;
//         max-height: 500px;
//         overflow: scroll;
//     }
// }