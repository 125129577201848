

.grid-list-no-icon{
    border-bottom: 1px solid var(--border-color);
    padding: 8px 0px;
    /* Last to remove border */
    &:last-child{
        border-bottom: none;
    }   
}

.carousel .slide img {
    max-height: 350px;
    width: auto;
}

.carousel .thumb img {
    max-height: 75px;
    height: 50px; 
    width: auto;
}

.car-image{
    position: relative;
    display: block;
    max-width: 550px;
    min-width: 100px;
    /* Make Image fit container */
    height: fill-available;
    max-height: 500px;
    overflow: hidden;
    
    img{
        /* Make Image fit container */
        width: 100% !important;
        border-radius: var(--border-radius);
        object-fit: cover !important;
    }
}

.no-border{
    border: none !important;
    border-bottom: none !important;

}


@media screen and (min-width: 500px){
    .carousel-image{
        height: 200px !important;
        object-fit: cover;
    }
}

@media screen and (max-width: 500px){
    .carousel-image{
        width: 400px !important;
        height: 200px !important;
    }
}

.grid-details{
    display: grid;
    grid-template-columns: 60% 40%;
    gap: 36px;

    .buy-container{ 
        overflow: hidden;
        border-radius: var(--border-radius);
        padding: 0;


        .header{
            display: block;
            position: relative;
            background-color : var(--primary-color);
            text-align: center;

            h1,h2,h3,h4,h5,h6{
                color: #ffffff;
            }


            .grey{
                color: #ffffff;
                opacity: 0.67;
            }

        }

        .footer{
            background-color : var(--secondary-color);
        }
    }
}


.grid-car-page{
    display: grid;
    grid-template-columns: auto 40%;

    .left{
        justify-self: start;
    }
    
    .right{
		justify-self: end;
    }

    .icon{
        width: 30px !important;
        margin: 0px;
        margin-top : 0px;
    }

}

.car-page-item{
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;


    .left{
        /* Align text middle vertically */
        display: flex;
        align-items: center;
        margin-left: 5px;
    }
}

.tick-list{
    min-height: 30px;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
    /* Vertical align */
    align-items: center;
    gap: 5px;
}

.grid-car-buy{
    display: grid;
    align-items: center;

    grid-template-columns: 30px auto;
    gap : 16px;

    .right{
        width: 100%;
    }

    button{
        width: -webkit-fill-available;
    }
}


.country-icon-container {
    border: none;
    border-radius: 30px;
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    background-color: var(--secondary-color);
    overflow: hidden;

    display: flex;
    place-items: center;
	justify-content: center;
	justify-items: center;
    img  {
        width: 70px !important;
        height: 70px !important;
        max-width: 70px !important;
        max-height: 70px !important;
    }
}