@import '../../../index.scss';

.carousel {
    touch-action: pan-y;
    overflow: hidden !important;
    .slider{
      li{
        list-style: none !important;
        list-style-type: none !important;
      }
    }
}

.carousel-slider{
  button{
    opacity: 0 !important;
  }
}

.carousel-slider:hover{
  button{
    opacity: 1 !important;
    background-color: rgba(0,0,0,0.2) !important;
  }
}

/* Shimmer effect */
.shimmer {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
} 