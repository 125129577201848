
    
.photos-container{
    grid-template-columns: 25% 25% 25% 25%;
    display: grid;
    gap : 8px;
}

.grid-form{
    display: grid;
    grid-template-columns: 250px auto;
    grid-gap: 16px;
    height: 100%; // Ensure the grid takes full viewport height
    width: -webkit-fill-available;
    
    .left{
      
    }
    
    .right{
        height: -webkit-fill-available;
        width: -webkit-fill-available;
        
        .form-content{
            padding: 0 10%;
        }
    }
}

@media screen and (min-width: 500px){
    .form-page{
        height: 100vh;
        overflow: hidden;
        display: block;
        position: relative;
        .left {
            display: flex;
            flex-direction: column;
            height: 100vh;
            
            .box-container {
                flex: 1; // Make the box container take the full height of its parent
                display: flex;
                flex-direction: column;
            }
        }
        .right{
            height: 90vh;
            margin-bottom: 10vh;
            overflow: auto;
        }
    }
}

@media screen and (min-width: 500px){
    .form-page{
        height: 100vh;
        overflow: hidden;
        display: block;
        position: relative;
    }
}

.list-items{
    background-color: transparent;
    border-radius: var(--border-radius);
    height: 35px;
    /* Vertical center text */
    display: flex;
    min-width: 250px;
    margin: 6px 0px;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
    gap: 5px;
    
    
    .number{
        background-color: var(--text-color);
        color: var(--background-color) !important;
        width : 20px;
        height: 20px;
        border-radius : 50px;
        text-align: center;
        align-self: center;
        display: flex;
        justify-content: center;
    }
    
    &.active{
        box-shadow: 0px 2px 5px 0px var(--shadow-color);
        background-color: var(--background-color);
    }

}

