.promotion-container{
    max-width: 1100px;

    .grid-33 {
        gap: 0px !important;
    }
}

@media screen and (max-width: 500px) {
    .promotion-container{
       width: 70%;
    }
}

.promoted-cars-grid{
    // display: grid;
    // grid-template-columns: 5% minmax(1100px,auto) 5%;
    // align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch; /* Ensures all cards have the same height */
    gap: 10px;
}

.promoted-grid{
    // display: grid;
    // grid-template-columns: 5% minmax(1100px,auto) 5%;
    // align-items: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    @media screen and (max-width: 500px) {
        .action-button{
            width: 40px;
            height: 40px;
        }
    }

    @media screen and (min-width: 500px) {
        .action-button{
            width: 64px;
            height: 64px;
        }
    }
}

.action-button{
    background-color: var(--background-color);
    border: 1px solid #E2E4E9;
    border-radius: 16px;
    display: flex; 
    justify-content: center;
    align-items: center; 

    .icon{
        width: 35px !important;
        height: 35px !important;
    }
}
.action-button:hover{
    cursor: pointer;
}