.multi-option-container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    
    .box-container{
        width: 100%;
        padding : 6px;
        margin: 0;
    }

    .isActive{
        background-color: var(--primary-color);
        h5{
            color: white !important;
        }
    }
}

.fit-box{
    width: fit-content !important;
}

.container-inline{
    display: inline-block;
    
    .fit-box{
        margin-top : 10px;
        margin-right: 10px;
    }
}

.box-click{
    cursor: pointer;
}