.compact-card {
    border: 1px solid var(--button-color);
    border-radius: 5%;
    overflow: hidden;
    
    .icon{
        width: 20px;
        margin: 0px;
        margin-top : 2px;
    }
    .inline{
        margin: 0px !important;
        margin-left: 2px !important;
    }


    .characteristics {
        overflow: hidden;
        max-height: 50px !important;
        text-align: justify;
    }

    .info-container{
        padding-left: 10px;
        padding-right: 10px;
    }
}

.compact-card {
    max-width: 350px;
    justify-content: space-between; /* Ensure consistent internal spacing */
}
@media screen and (min-width: 500px) {
    .compact-card {
        display: grid;
        width: 100%;
        word-break: break-all;
        background-color : var(--background-color);
        grid-template-rows: 225px 50px auto;
        gap: 5px;
    }

    .carousel-image-small{
        width: 100% !important; /* Set desired width */
        height: 225px !important; /* Set desired height */
        object-fit: contain; /* Stretch to fill the specified dimensions */
        image-rendering: auto; /* Render the image with the best quality available */
    }
}

@media screen and (max-width: 500px) {
    .compact-card {
        display: grid;
        width: 100%;
        word-break: break-all;
        background-color : var(--background-color);
        grid-template-rows: 200px 50px auto ;
        gap: 5px;
    }

    .carousel-image-small{
        width: 100% !important; /* Set desired width */
        height: 200px !important; /* Set desired height */
        object-fit: contain; /* Stretch to fill the specified dimensions */
        image-rendering: auto; /* Render the image with the best quality available */
    }
}

.promoted{
    margin: 15px;
    background-color: var(--button-color) !important;
    border: 1px solid var(--border-color) !important;
	border-radius: var(--border-radius) ;
    gap: 4px;
    svg{
         padding: 0px !important;
         margin: 0px !important;
         height: 15px;
         width: 15px;
     }
 
 }
 .promoted > * {
    display: inline;
    color: var(--background-color);
 }