.country-icon-container-language {
    border: none;
    border-radius: 30px;
    min-width: 25px;
    min-height: 25px;
    max-width: 25px;
    max-height: 25px;
    overflow: hidden;

    display: flex;
	place-items: center;
	justify-content: center;
	justify-items: center;
    img  {
        width: 50px !important;
        height: 50px !important;
        max-width: 50px !important;
        max-height: 50px !important;
    }
}